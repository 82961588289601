import {PhoneNumber} from '@controls/phone-number';

import {CharterFilterGuestListParams} from './charter-guest.model';

import {CountryDto} from '../directories';
import {PaginationDto} from '../response';

export interface CharterPersonsListData {
  charterId: number;
  isTrip: boolean;
}

export interface CharterPersonsListDto {
  list: CharterPersonsItemDto[];
  pagination: PaginationDto;
}

export class BrokerPhone extends PhoneNumber {
  constructor(
    public readonly id: number | null,
    phoneCountryCode: string,
    phoneNumber: string,
    public readonly fullPhoneNumber?: string | null,
  ) {
    super(phoneCountryCode, phoneNumber);
  }
}

export class BrokerEmail {
  constructor(
    public readonly id: number,
    public readonly email: string,
  ) {
  }
}

export interface BrokerPhoneControl {
  id: number | null;
  phone: BrokerPhone | null;
}

export class CharterFilterPersonsListParams {
  constructor(
    public readonly page: number = 1,
    public readonly limit: number,
    public readonly search: string | null = null,
  ) {
  }
}

export class BoatFilterPersonsListParams {
  constructor(
    public readonly page: number = 1,
    public readonly limit: number,
    public readonly search: string | null = null,
  ) {
  }
}

export class BoatPersonsListParams extends BoatFilterPersonsListParams {
  constructor(
    public readonly boatId: number,
    public readonly charterId: number,
    params: BoatFilterPersonsListParams,
  ) {
    super(params.page, params.limit, params.search);
  }
}

export class AddPersonsToCharterPayload {
  constructor(
    public readonly charterId: number,
    public readonly contactPersonId: number,
  ) {
  }
}

export interface AddPersonsToCharterResponse {
  charterContactPersonId: number;
}

export class RemovePersonsFromCharterPayload extends AddPersonsToCharterPayload {
  constructor(
    charterId: number,
    contactPersonId: number,
  ) {
    super(charterId, contactPersonId);
  }
}

export interface CharterPersonsItemDto {
  boatContactPersonId: number;
  charterContactPersonId: number;
  firstName: string;
  lastName: string;
  email: string;
  phones: PersonsPhone[];
  notes: string;
  added?: boolean;
}

export class PersonsPhone extends PhoneNumber {
  constructor(
    public readonly id: number | null,
    phoneCountryCode: string,
    phoneNumber: string,
    public readonly fullPhoneNumber?: string | null,
  ) {
    super(phoneCountryCode, phoneNumber);
  }
}

export class CharterPersonsListParams extends CharterFilterPersonsListParams {
  constructor(
    public readonly charterId: number,
    params: CharterFilterGuestListParams,
  ) {
    super(params.page, params.limit);
  }
}

export class CharterPersonsDirectories {
  constructor(
    public readonly countries: CountryDto[],
  ) {
  }
}

export interface CharterPersonForm {
  firstName: string;
  lastName: string;
  email: string;
  phones: BrokerPhoneControl[] | null;
  removePhones: number[] | null;
  notes: string;
}

export class PersonPhone extends PhoneNumber {
  constructor(
    public readonly id: number | null,
    phoneCountryCode: string,
    phoneNumber: string,
    public readonly fullPhoneNumber?: string | null,
  ) {
    super(phoneCountryCode, phoneNumber);
  }
}

export class CreatePersonPayload {
  readonly firstName: string;
  readonly lastName: string;
  readonly email: string;
  readonly phonesNew: BrokerPhone[] | null;
  readonly phonesOld: BrokerPhone[] | null;
  readonly notes: string | null;

  constructor(
    form: CharterPersonForm,
  ) {
    const {firstName, lastName, phones, email, notes} = form;
    this.firstName = firstName;
    this.lastName = lastName;
    this.email = email;
    this.notes = notes;

    const phonesPayload = phones?.reduce((acc: [PersonPhone[], PersonPhone[]], curr) => {
      const index = curr.id ? 0 : 1;
      if (curr.phone) {
        const phoneItem = new PersonPhone(curr.id, curr.phone.phoneCountryCode, curr.phone.phoneNumber);
        acc[index].push(phoneItem);
      }
      return acc;
    }, [[], []]);

    this.phonesOld = phonesPayload ? phonesPayload[0] : null;
    this.phonesNew = phonesPayload ? phonesPayload[1] : null;
  }
}

export class UpdatePersonPayload extends CreatePersonPayload {
  readonly removePhones: number[] | null;

  constructor(
    form: CharterPersonForm,
  ) {
    super(form);
    this.removePhones = form.removePhones;
  }
}
