import {CharterPaymentDetails} from './charter-payment-details.model';
import {CharterTransactionDataDto} from './charter.model';

import {AppChartType} from '../dashboard';
import {CurrencyDto} from '../directories';
import {ReportViewType} from '../financial-report';

export class CharterBoatExpenseDto {
  constructor(
    public readonly paymentDetails: CharterPaymentDetails,
    public readonly category: CharterTransactionDataDto,
    public readonly convertCurrency: CurrencyDto,
    public readonly charterProfit: CharterExpenseProfitDto,
  ) {
  }
}

export class CharterExpenseProfitDto {
  constructor(
    public readonly grossIncome: number,
    public readonly percent: number,
    public readonly value: number,
  ) {
  }
}

export class CharterBoatExpensePayload {
  constructor(
    public readonly boatId: number,
    public readonly charterId: number,
    public readonly currencyId: number | null,
  ) {
  }
}

export class CharterBoatExpenseDirectories {
  constructor(
    public readonly boatCurrency: CurrencyDto | null,
    public readonly charterCurrency: CurrencyDto | null,
  ) {
  }
}

export class CharterBoatExpensesFilter {
  constructor(
    public currencyId: number | null = null,
  ) {
  }
}

export class CharterBoatExpensesParams {
  constructor(
    public currencyId: number,
  ) {
  }
}

export class DownloadCharterBoatExpensesPayload {
  constructor(
    public readonly boatId: number,
    public readonly charterId: number,
    public readonly currencyId: number,
  ) {
  }
}

export class CharterExpensesReportChartParams {
  constructor(
    public readonly chartType: AppChartType,
    public readonly viewType: ReportViewType,
    public readonly id: number,
    public readonly charterId: number,
  ) {
  }
}
