import {MonthRange} from '@controls/month-range/models/month-range.model';
import {buildURLParams} from '@helpers/build-url-params';

import {CharterProfit,CharterCompletedStatus, CharterPeriodStatus} from './charter-short-info.model';
import {CharterCommonType, CharterKindType, SystemCharterType} from './charter.model';
import {CharterPeriodStatusItem, CharterTypeItem, PeriodType, PeriodTypeItem} from './common';

import {ChartData} from '../../modules/chart';
import {CharterContractStatusDto, CurrencyDto} from '../directories';
import {MonthExpenseReportItem} from '../financial-report';
import {ConditionalStatus, OverviewButtons} from '../general';
import {PaginationDto} from '../response';
import {GroupRange, SortItem} from '../shared';

export interface CharterOverviewParams {
  mode: OverviewButtons | null;
  year?: string | null;
  month?: string | null;
  yearTail?: string | null;
  monthTail?: string | null;
  excludeUpcomingCharters?: string | null;
  excludeOwnerCharters?: string | null;
}

export interface CharterOverviewPayload {
  mode: string;
  year: number;
  excludeUpcomingCharters: number;
  excludeOwnerCharters: number;
  month?: number | null;
  yearTail?: number | null;
  monthTail?: number | null;
}

export interface CharterOverviewForm extends DurationForm{
  excludeUpcomingCharters: boolean;
  excludeOwnerCharters: boolean;
}

export interface DurationForm {
  mode: OverviewButtons;
  year: number;
  month?: number;
  duration?: MonthRange;
}

export class CharterOverviewStatisticDto {
  constructor(
    public total: CharterOverviewTotalStatisticItem | null,
    public charters: CharterOverviewStatisticItem | null,
    public ownerCharters: CharterOverviewStatisticItem | null,
    public trip: CharterOverviewStatisticItem | null,
    public allInclusiveCharters: CharterOverviewStatisticItem | null,
  ) {
  }
}

export class CharterOverviewStatisticItem {
  constructor(
    public term: CharterOverviewStatisticTerm,
    public daily: CharterOverviewStatisticDaily,
    public income: CharterOverviewStatisticIncome,
    public average: CharterOverviewStatisticAverage,
    public profit?: CharterOverviewProfit,
  ) {
  }
}

export class CharterOverviewTotalStatisticItem extends CharterOverviewStatisticItem {
  constructor(
    public calendarDays: number,
    term: CharterOverviewStatisticTerm,
    daily: CharterOverviewStatisticDaily,
    income: CharterOverviewStatisticIncome,
    average: CharterOverviewStatisticAverage,
  ) {
    super(term, daily, income, average);
  }
}

export class CharterOverviewStatisticTerm {
  constructor(
    public days: number,
    public quantity: number,
  ) {
  }
}

export class CharterOverviewStatisticDaily {
  constructor(
    public hours: number,
    public quantity: number,
  ) {
  }
}

export class CharterOverviewStatisticIncome {
  constructor(
    public gross: number,
    public net: number,
  ) {
  }
}

export class CharterOverviewStatisticAverage {
  constructor(
    public days: number,
    public hours: number,
    public guests: number,
  ) {
  }
}

export class CharterOverviewIncomeVsExpenses {
  constructor(
    public amountNetIncomeVsExpenses: CharterOverviewIncomeVsExpensesAmount,
    public totalCharterExpenses: CharterOverviewTotalCharterExpenses,
  ) {
  }
}

export class CharterOverviewIncomeVsExpensesCharts {
  constructor(
    public amountNetIncomeVsExpenses: CharterCharts,
    public totalCharterExpenses: CharterOverviewTotalCharterExpensesCharts,
  ) {
  }
}

export class CharterOverviewProfitAndLoss {
  constructor(
    public charterProfitAndLoss: CharterCharts,
    public charterProfit: CharterOverviewProfit,
  ) {
  }
}

export class CharterOverviewProfit {
  constructor(
    public readonly grossIncome: number,
    public readonly percent: number,
    public readonly value: number,
  ) {
  }
}

export class CharterOverviewProfitAndLossDto {
  constructor(
    public charterProfitAndLoss: CharterChartsDto,
    public charterProfit: CharterOverviewProfit,
  ) {
  }
}

export class CharterCharts {
  constructor(
    public title: string,
    public items: ChartData[] | null,
  ) {
  }
}

export class CharterChartsDto {
  constructor(
    public title: string,
    public items: CharterOverviewChartItem[] | null,
  ) {
  }
}

export class CharterOverviewIncomeVsExpensesAmount {
  constructor(
    public title: string,
    public items: CharterOverviewChartBaseItem[] | null,
  ) {
  }
}

export class CharterOverviewTotalCharterExpensesCharts {
  constructor(
    public items: ChartData[] | null,
    public total: number,
    public isHidden: boolean,
  ) {
  }
}

export class CharterOverviewTotalCharterExpenses {
  constructor(
    public items: CharterOverviewChartItem[] | null,
    public total: number,
    public isHidden: boolean,
  ) {
  }
}

export class  CharterOverviewChartBaseItem {
  constructor(
    public name: string,
    public currentExpense: number,
  ) {
  }
}

export class CharterOverviewChartItem extends CharterOverviewChartBaseItem {
  constructor(
    public color: string,
    name: string,
    currentExpense: number,
  ) {
    super(name, currentExpense);
  }
}

export class CharterOverviewYears {
  constructor(
    public readonly years: number[],
  ) {
  }
}

export class CharterOverviewIncome {
  constructor(
    public netIncome: CharterOverviewNetIncomeDto,
  ) {
  }
}

export class CharterOverviewNetIncomeDto {
  constructor(
    public items: MonthExpenseReportItem[],
    public title: number,
    public total: number,
  ) {
  }
}

export class CharterOverviewGraphDto {
  constructor(
    public readonly annualComparative: CharterOverviewGraph,
  ) {
  }
}

export class CharterOverviewGraph {
  constructor(
    public readonly title: string,
    public readonly items: CharterOverviewGraphItem[],
  ) {
  }
}

export class CharterOverviewGraphItem {
  constructor(
    public readonly name: string,
    public readonly yearOneValue: number | null,
    public readonly yearTwoValue: number | null,
  ) {
  }
}

export class CharterOverviewGraphData {
  constructor(
    public name: string,
    public firstYear: Array<number | null> = [],
    public secondYear: Array<number | null> = [],
  ) {
  }
}

export class CharterOverviewGraphForm {
  constructor(
    public statisticMode: number,
    public year: number,
    public yearTail: number,
  ) {
  }
}

export class CharterOverviewGraphPayload {
  constructor(
    public statisticMode: number,
    public year: number,
    public yearTail: number,
    public excludeUpcomingCharters: ConditionalStatus,
    public excludeOwnerCharters: ConditionalStatus,
  ) {
  }
}

export class CharterOverviewGraphOption {
  constructor(
    public title: string,
    public value: number,
  ) {
  }
}

export interface CharterOverviewTableRawFormI {
  periodStatus: CharterPeriodStatusItem[];
  name: string;
  charterType: CharterTypeItem[];
  periodType: PeriodType;
  timeStart: CharterFullLocationFilterForm;
  timeEnd: CharterFullLocationFilterForm;
  grossIncome: GroupRange;
  contractStatus: CharterContractStatusDto[];
  profit: GroupRange;
}

export interface CharterFullLocationFilterForm {
  date: string | null;
  location: CharterOverviewLocation | null;
}

export interface CharterOverviewLocation {
  address: string;
  lat: number;
  lng: number;
  timeZoneName: string;
}

export type CharterOverviewTableFilter = {
  [key in keyof CharterOverviewTableRawFormI]?: any | any[];
};

export type CharterOverviewTableParams = {
  [key in keyof CharterOverviewPayload]?: any | any[];
};

export class CharterOverviewDataFilter {
  constructor(
    public filter: CharterOverviewTableFilter = {},
    public params: CharterOverviewTableParams = {},
    public page: number | null = 1,
    public sort: SortItem | null = null,
    public search: string = '',
  ) {
  }
}

export class CharterOverviewDataDto {
  constructor(
    public readonly models: CharterOverviewTableItemDto[],
    public readonly pagination: PaginationDto,
    public readonly grossIncomeRange: CharterOverviewIncomeRange,
    public readonly profitRange: CharterOverviewIncomeRange,
  ) {
  }
}

export class CharterOverviewTableItemDto {
  constructor(
    public readonly id: number,
    public readonly name: string,
    public readonly periodStatus: CharterPeriodStatus,
    public readonly systemCharterType: SystemCharterType,
    public readonly charterType: CharterKindType,
    public readonly charterPeriod: string,
    public readonly currency: CurrencyDto,
    public readonly status: CharterCompletedStatus,
    public readonly dateTimeStart: string,
    public readonly dateTimeEnd: string,
    public readonly locationPickUp: CharterOverviewLocation,
    public readonly locationDropOff: CharterOverviewLocation,
    public readonly grossIncome: number,
    public readonly netIncome: number,
    public readonly contractStatus: number,
    public readonly profit: CharterProfit,
    public readonly periodType: PeriodType,
    public readonly timeZoneNameStart: string,
    public readonly timeZoneNameEnd: string,
  ) {
  }
}

export class CharterOverviewIncomeRange {
  constructor(
    public readonly min: number | null,
    public readonly max: number | null,
  ) {
  }
}

export class CharterOverviewDirectories {
  constructor(
    public readonly periodStatus: CharterPeriodStatusItem[] = [],
    public readonly charterType: CharterTypeItem[] = [],
    public readonly contractStatus: CharterContractStatusDto[] = [],
    public readonly periodType: PeriodTypeItem[] = [],
  ) {
  }
}

export class CharterOverviewDataFilterDto {
  constructor(
    public readonly name: string | null,
    public readonly dateTimeStart: string | null,
    public readonly locationStart: string | null,
    public readonly dateTimeEnd: string | null,
    public readonly locationEnd: string | null,
    public readonly grossIncomeMin: number | null,
    public readonly grossIncomeMax: number | null,
    public readonly profitMin: number | null,
    public readonly profitMax: number | null,
    public readonly page: number | null,
    public readonly limit: number | null,
    public readonly search: string | null = null,
    periodStatus: number[] | null = null,
    charterType: number[] | null = null,
    periodType: number[] | null = null,
    contractStatus: number[] | null = null,
    public readonly mode: string,
    public readonly year: number,
    public readonly excludeUpcomingCharters: number,
    public readonly excludeOwnerCharters: number,
    public readonly month: number | null,
    public readonly yearTail: number | null,
    public readonly monthTail: number | null,
  ) {
    this.mapToArrayParams(periodStatus, 'periodStatus');
    this.mapToArrayParams(charterType, 'charterType');
    this.mapToArrayParams(contractStatus, 'contractStatus');
    this.mapToArrayParams(periodType, 'periodType');
  }

  private mapToArrayParams(list: (number | string)[] | null, parentKey: string) {
    if (!list) {
      return;
    }
    const params = buildURLParams(list, true, parentKey);
    Object.keys(params).forEach(key => {
      this[key] = params[key];
    });
  }
}

export interface CharterOverviewNavigationParams {
  type: CharterCommonType | null;
  isTrip: boolean;
}
