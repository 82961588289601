import {UserPermissions} from '../../../modules/permissions';

export class CharterNavItemBase {
  constructor(
    public readonly url: string,
    public readonly name: string,
  ) {
  }
}

export class CharterNavItem extends CharterNavItemBase {
  constructor(
    public readonly url: string,
    public readonly name: string,
    public readonly icon: string | null = null,
    public readonly disabled: boolean = false,
    public readonly permission: UserPermissions[] | null = null,
    public readonly featureToggle: string | null = null,
  ) {
    super(url, name);
  }
}
