import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {ReactiveFormsModule} from '@angular/forms';
import {TranslocoModule} from '@ngneat/transloco';

import {FormErrorsModule} from '@controls/form-errors/form-errors.module';
import {SelectModule} from '@controls/select/select.module';

import {PhoneNumberComponent} from './components/phone-number/phone-number.component';

@NgModule({
  declarations: [PhoneNumberComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    SelectModule,
    FormErrorsModule,
    TranslocoModule,
  ],
  exports: [PhoneNumberComponent],
})
export class PhoneNumberModule { }
