export enum UserPreferenceListSections {
  chartererInfo = 'charterer-info',
  openingText = 'opening-text',
  boatCharterInfo = 'boat-charter-info',
  arrival = 'arrival',
  departure = 'departure',
  specialEvents = 'special-events-on-board',
  activities = 'charter-activities',
  swimming = 'swimming',
  crewInteraction = 'interaction-with-the-crew',
  proposedItinerary = 'proposed-itinerary',
  medical = 'medical',
  mealsTimes = 'serving-meals-times',
  cuisines = 'cuisines',
  breakfastType = 'cuisines-breakfast-type',
  breadPreferences = 'cuisines-bread-preferences',
  freshFruits = 'breakfast-fresh-fruits',
  breakfastDrink = 'breakfast-drink',
  lunchStyle = 'lunch-style-of-meal',
  dinnerStyle = 'dinner-style-of-meal',
  lunchAndDinnerFirst = 'lunch-and-dinner-first',
  lunchAndDinnerSecond = 'lunch-and-dinner-second',
  lunchAndDinnerPreferences  = 'lunch-and-dinner-preferences',
  desserts = 'desserts',
  snacks = 'snacks',
  kidsMenu = 'kids-menu',
  beverages = 'beverages',
  alcohol = 'alcohol',
  alcoholAdvice = 'alcohol-advice',
  alcoholOther = 'alcohol-other',
  alcoholPreferences = 'alcohol-preferences',
  importantNotes = 'additional-important-notes',
  guidelines = 'general-guidelines',
  greatJob = 'great-job',
}

export enum UserPreferenceListSectionsSystemRoutes {
  empty = '',
  notFound = '**',
}
