import {CharterKindType, SystemCharterType} from './charter.model';

import {CurrencyDto} from '../directories';
import {LocationDto} from '../location';

export enum CharterPeriodStatus {
  Running = 1,
  Upcoming = 2,
  PastCharter = 3
}

export enum CharterCompletedStatus {
  Draft = 1,
  Clean,
  Completed,
  Archived
}

export interface CharterIncomeI {
  net: number;
  gross: number;
  currency: CurrencyDto;
}

export interface CharterLocationPointI {
  lat: number;
  lng: number;
  address: string;
}

interface CharterTasksDto {
  readonly all: number;
}

export interface CharterShortInfoI {
  id: number;
  name: string;
  periodStatus: CharterPeriodStatus;
  contractStatus: number;
  errors: string[];
  charterType: CharterKindType;
  dateLastEdit: string;
  dateTimeStart: string;
  timeZoneNameStart: string;
  locationPickUp: CharterLocationPointI;
  dateTimeEnd: string;
  timeZoneNameEnd: string;
  locationDropOff: CharterLocationPointI;
  guests: number;
  income: CharterIncomeI;
  status: CharterCompletedStatus;
  systemCharterType: SystemCharterType;
  profit: CharterProfit | null;
  existsApaTransactions?: boolean;
  existsBoatCharterExpenses?: boolean;
  tasks?: CharterTasksDto;
}

export class CharterShortInfo implements CharterShortInfoI {
  readonly id: number;
  readonly name: string;
  readonly periodStatus: CharterPeriodStatus;
  readonly charterType: CharterKindType;
  readonly dateLastEdit: string;
  readonly dateTimeStart: string;
  readonly timeZoneNameStart: string;
  readonly locationPickUp: LocationDto;
  readonly dateTimeEnd: string;
  readonly timeZoneNameEnd: string;
  readonly locationDropOff: LocationDto;
  readonly guests: number;
  readonly income: CharterIncomeI;
  readonly status: CharterCompletedStatus;
  readonly systemCharterType: SystemCharterType;
  readonly contractStatus: number;
  readonly errors: string[];
  readonly existsApaTransactions?: boolean;
  readonly existsBoatCharterExpenses?: boolean;
  readonly profit: CharterProfit | null;
  readonly tasks?: CharterTasksDto;

  constructor(data: CharterShortInfoI) {
    this.id = data.id;
    this.name = data.name;
    this.periodStatus = data.periodStatus;
    this.charterType = data.charterType;
    this.dateLastEdit = data.dateLastEdit;
    this.dateTimeStart = data.dateTimeStart;
    this.timeZoneNameStart = data.timeZoneNameStart;
    this.locationPickUp = data.locationPickUp;
    this.dateTimeEnd = data.dateTimeEnd;
    this.timeZoneNameEnd = data.timeZoneNameEnd;
    this.locationDropOff = data.locationDropOff;
    this.guests = data.guests;
    this.income = data.income;
    this.status = data.status;
    this.systemCharterType = data.systemCharterType;
    this.errors = data.errors;
    this.contractStatus = data.contractStatus;
    this.existsApaTransactions = data.existsApaTransactions;
    this.existsBoatCharterExpenses = data.existsBoatCharterExpenses;
    this.profit = data.profit;
    this.tasks = data.tasks;
  }

}

export class CharterProfit {
  readonly value: number;
  readonly percent: number;
  readonly lastDateUploaded: string;
  readonly currency: CurrencyDto;

  constructor(data: CharterProfit) {
    this.value = data.value;
    this.percent = data.percent;
    this.lastDateUploaded = data.lastDateUploaded;
    this.currency = data.currency;
  }
}
