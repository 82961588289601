import {CharterPayloadDto} from './charter.model';

import {BoatPayloadDto} from '../boat';
import {PaginationListDto, PaginationPayload} from '../response';

export interface PreferenceSettingsDto {
  readonly open: string;
  readonly finish: string;
}

export type PreferenceSettingsPayload = PreferenceSettingsDto & BoatPayloadDto;

export interface AddCharterPreferenceListPayload extends CharterPayloadDto {
  readonly chartererName: string;
  readonly email: string;
}

export interface UpdateCharterPreferenceListPayload extends AddCharterPreferenceListPayload {
  readonly preferenceList: number;
}

export interface DeleteCharterPreferenceListPayload extends CharterPayloadDto {
  readonly preferenceList: number;
}

export type CharterPreferenceListParams = CharterPayloadDto & Pick<PaginationPayload, 'page'>;

export interface CharterPreferenceListPayloadDto extends CharterPayloadDto {
  readonly preferenceList: number | string;
}

export interface ChangeCharterPreferenceListStatusPayload extends CharterPreferenceListPayloadDto {
  readonly status: CharterPreferenceListStatus;
}

export interface ChangeCharterPreferenceListPasswordPayload extends CharterPreferenceListPayloadDto {
  readonly password: string;
}

export enum CharterPreferenceListStatus {
  unlocked = 1,
  locked,
}

interface CharterPreferenceListProgress {
  readonly all: number;
  readonly completed: number;
}

export interface CharterPreferenceListShareInfo {
  readonly token: string;
  readonly password: string;
}

interface CharterPreferenceListCreator {
  readonly name: string;
  readonly role: string;
}

export interface CharterPreferenceListItemDto {
  readonly id: number;
  readonly dateCreated: string | null;
  readonly dateCompleted: string | null;
  readonly chartererName: string;
  readonly email: string;
  readonly boatCrew: CharterPreferenceListCreator;
  readonly status: CharterPreferenceListStatus;
  readonly progress: CharterPreferenceListProgress;
  readonly sharing: CharterPreferenceListShareInfo;
}

export type CharterPreferenceListItems = PaginationListDto<CharterPreferenceListItemDto>;

interface CharterPreferenceListBoatDto {
  readonly name: string;
  readonly boatType: string;
  readonly boatLength: number;
}

interface CharterPreferenceListDataDto {
  readonly dateCompleted: string | null;
  readonly chartererName: string;
  readonly email: string;
}

interface CharterPreferenceListViewHeaderDto {
  readonly boat: CharterPreferenceListBoatDto;
  readonly charter: {name: string};
  readonly list: CharterPreferenceListDataDto;
}

export interface CharterPreferenceListViewBodyDto {
  readonly arrival: MovementDto;
  readonly departure: MovementDto;
  readonly specialEventsOnBoard: SpecialEventsOnBoard;
  readonly charterActivities: ListDto;
  readonly swimming: LikeWithNotesDto;
  readonly interactionWithTheCrew: InteractionWithTheCrewDto;
  readonly proposedItinerary: NotesDto;
  readonly medical: LikeWithNotesDto;
  readonly servingMealsTimes: ServingMealsTimesDto;
  readonly cuisines: ListDto;
  readonly cuisinesBreakfastType: ListDto;
  readonly cuisinesBreadPreferences: ListWithNotesDto;
  readonly breakfastFreshFruits: ListDto;
  readonly breakfastDrink: BreakfastDrinkDto;
  readonly lunchStyleOfMeal: StyleOfMealDto;
  readonly dinnerStyleOfMeal: StyleOfMealDto;
  readonly lunchAndDinnerFirst: LunchAndDinnerDto;
  readonly lunchAndDinnerSecond: ListWithNotesDto;
  readonly lunchAndDinnerPreferences: ListWithNotesDto;
  readonly desserts: ListWithNotesDto;
  readonly snacks: LikeWithNotesDto;
  readonly kidsMenu: LikeWithNotesDto;
  readonly beverages: BeveragesDto;
  readonly alcohol: AlcoholDto;
  readonly alcoholAdvice: AlcoholAdviceDto;
  readonly alcoholOther: AlcoholDto;
  readonly alcoholPreferences: NotesDto;
  readonly additionalImportantNotes: NotesDto;
}

interface MovementDto {
  readonly date: string
  readonly time: string
  readonly notes: string
  readonly airport: string
  readonly transfer: boolean
  readonly flightNumber: string
}

interface SpecialEventsOnBoard {
  readonly notes: string | null;
  readonly events: CharterEventDto[]
}

interface CharterEventDto {
  readonly id: number;
  readonly date: string;
  readonly name: string;
}

interface ListDto {
  readonly list: string[];
}

interface NotesDto {
  readonly notes: string;
}

interface LikeWithNotesDto extends NotesDto {
  readonly like: boolean;
}

type ListWithNotesDto = ListDto & NotesDto;

interface InteractionWithTheCrewDto {
  readonly interaction: string;
}

interface ServingMealsTimesDto {
  readonly serving: ServingDto[];
}

interface ServingDto {
  readonly id: number;
  readonly time: string;
  readonly name: string;
}

interface BreakfastDrinkDto {
  readonly tea: string[];
  readonly milk: string[];
  readonly coffee: string[];
}

interface StyleOfMealDto extends ListDto {
  readonly times: string;
  readonly outside: boolean;
}

interface LunchAndDinnerDto {
  readonly fish: string[];
  readonly meat: string[];
}

interface BeveragesDto extends ListDto {
  readonly drinks: DrinkDto[];
}

interface DrinkDto {
  readonly name: string;
  readonly quantity: string;
}

interface AlcoholDto {
  drinks: AlcoholDrinkDto[]
}

interface AlcoholDrinkDto {
  readonly kind: string;
  readonly label: string;
  readonly quantity: string;
}

interface AlcoholAdviceDto extends ListWithNotesDto {
  readonly priceTo: string;
  readonly priceFrom: string;
}

export interface CharterPreferenceListViewDto {
  readonly header: CharterPreferenceListViewHeaderDto;
  readonly body: CharterPreferenceListViewBodyDto;
}
