import {LocationDto} from '../location';

export enum CrewGuestDownloadType {
  Excel = 'excel',
  Pdf = 'pdf'
}

export class DownloadCrewGuestsModalForm {
  constructor(
    public readonly lastPort: LocationDto,
    public readonly nextPort: LocationDto,
    public readonly departureDate: string,
    public readonly arrivalDate: string,
    public readonly crews: number[] | null,
    public readonly guests: number[] | null,
    public readonly captain: string | null,
  ) {
  }
}

export class CrewGuestListsPayload {
  constructor(
    public readonly boatId: number,
    public readonly charterId: number | null,
  ) {
  }
}

export class CrewGuestListsDto {
  constructor(
    public readonly crews: DownloadCrewsListDto[],
    public readonly guests: DownloadGuestsListDto[],
  ) {
  }
}

export class DownloadCrewsListDto {
  constructor(
    public readonly id: number,
    public readonly isCaptain: boolean,
    public readonly username: string,
    public readonly position: string,
  ) {
  }
}

export class DownloadGuestsListDto {
  constructor(
    public readonly id: number,
    public readonly username: string,
    public readonly isRunning: boolean,
    public readonly inCharter: boolean,
  ) {
  }
}

export class CrewGuestListSelection {
  constructor(
    public readonly crews: CrewListSelectionItem[],
    public readonly guests: GuestListSelectionItem[],
  ) {
  }
}

export class CrewListSelectionItem extends DownloadCrewsListDto {
  constructor(
    public readonly id: number,
    public readonly isCaptain: boolean,
    public readonly username: string,
    public readonly position: string,
    public readonly hidden = false,
  ) {
    super(id, isCaptain, username, position);
  }
}

export class GuestListSelectionItem extends DownloadGuestsListDto {
  constructor(
    public readonly id: number,
    public readonly username: string,
    public readonly isRunning: boolean,
    public readonly inCharter: boolean,
    public readonly hidden = false,
  ) {
    super(id, username, isRunning, inCharter);
  }
}

export interface DownloadData {
  readonly formValue: DownloadCrewGuestsModalForm;
  readonly type: CrewGuestDownloadType;
}

export class DownloadCrewGuestPayload {
  readonly lastPort: string | null;
  readonly nextPort: string | null;
  readonly departureDate: string | null;
  readonly arrivalDate: string | null;
  readonly crews: number[] | null = null;
  readonly guests: number[] | null = null;
  readonly captain: string | null = null;

  constructor(
    form: DownloadCrewGuestsModalForm,
  ) {
    this.lastPort = form.lastPort?.address;
    this.nextPort = form.nextPort?.address;
    this.departureDate = form.departureDate;
    this.arrivalDate = form.arrivalDate;
    if (form.crews?.length) {
      this.mapLists(form.crews, 'crews');
    }
    if (form.guests?.length) {
      this.mapLists(form.guests, 'guests');
    }
    if (form.captain) {
      this.captain = form.captain;
    }
  }

  private mapLists(items: number[], name: string): void {
    items.forEach((item, index) => {
      if (item) {
        this[`${name}[${index}]`] = item;
      }
    });
  }
}

export class BoatDownloadCrewGuestsPayload extends DownloadCrewGuestPayload {
  readonly boatId: number;
  readonly charterId?: number;

  constructor(
    boatId: number,
    form: DownloadCrewGuestsModalForm,
    charterId: number | null,
  ) {
    super(form);
    if (charterId) {
      this.charterId = charterId;
    }
    this.boatId = boatId;
  }
}

export interface CaptainGroupSelection {
  group: string;
  list: string[];
}
