import {ApaFinancialItemDto} from './charter-apa.model';
import {CharterShortInfo} from './charter-short-info.model';

import {BoatShortInfoDto} from '../boat';
import {CurrencyDto} from '../directories';
import {PaginationDto} from '../response';

export interface ShareCharterApaPayload {
  readonly charter: number;
}

export interface ShareCharterDataDto {
  readonly token: string;
  readonly password: string;
}

export interface ShareCharterDataForm {
  readonly password: string;
}

export class ShareCharterApaEditPayload implements ShareCharterApaPayload {
  constructor(
    public readonly charter: number,
    public readonly password: string,
  ) {
  }
}

export interface ShareApaReportAuthForm {
  readonly password: string;
}

export class ShareApaReportAuthPayload {
  constructor(
    public readonly token: string,
    public readonly password: string,
  ) {
  }
}

export interface ShareApaReportAuthDto {
  readonly jwt: string;
}

export class ShareApaTransactionsPayload {
  constructor(
    public readonly jwt: string,
    public readonly page: number,
    public readonly search?: string,
  ) {
  }
}

export interface ShareApaTransactionsFilter {
  readonly page: number;
  readonly search: string;
}

export type ShareApaTransactionsFilterParams = {
  [key in keyof ShareApaTransactionsFilter]?: any | any[];
};

export interface ShareApaReportFinancialData {
  models: ApaFinancialItemDto[];
  pagination: PaginationDto;
}

export interface ShareCharterInfo {
  boat: BoatShortInfoDto;
  charter: CharterShortInfo;
  currency: CurrencyDto;
}
