import {
  parseUploadFileName,
  parseUploadFileNames,
  parseUploadOriginalNames,
} from '@helpers/parse-new-upload-file-names';

import {SystemCharterType} from './charter.model';

import {booleanToConditionalStatus, ConditionalStatus} from '../general';
import {GuestForm, GuestFormDto, GuestInfoItemDto, GuestPhoneForm} from '../guests';
import {PaginationDto} from '../response';

export enum CharterAllGuestListType {
  BoatGuests = 1,
  FleetGuests
}

export class CharterFilterGuestListParams {
  constructor(
    public readonly page: number = 1,
    public readonly limit: number,
  ) {
  }
}

export class CharterGuestListParams extends CharterFilterGuestListParams {
  constructor(
    public readonly boatId: number,
    public readonly charterId: number,
    params: CharterFilterGuestListParams,
  ) {
    super(params.page, params.limit);
  }
}

export class CharterFilterAllGuestListParams {
  constructor(
    public readonly type: CharterAllGuestListType,
    public readonly page: number = 1,
    public readonly limit: number,
    public readonly search: string | null = null,
  ) {
  }
}

export class CharterFilterAllGuestListPayload extends CharterFilterAllGuestListParams {
  constructor(
    public readonly boat: number,
    public readonly charter: number,
    params: CharterFilterAllGuestListParams,
  ) {
    super(params.type, params.page, params.limit, params.search);
  }
}

export interface CharterGuestListDto {
  guestList: CharterGuestItemDto[];
  pagination: PaginationDto;
  maxGuest?: number;
}

export interface CharterGuestItemDto extends GuestInfoItemDto {
  addedToAnyOtherCharter: boolean;
  addedToCharter: boolean;
  deleted: boolean;
  charterGuest: number;
}

export interface RemoveCharterGuestPayload {
  charterGuestId: number;
  systemCharterType: SystemCharterType;
}

export class BoatGuestFormPayload {
  constructor(
    public readonly guest: number,
    public readonly boat: number,
    public readonly charter: number | null,
  ) {
  }
}

export interface CharterGuestFormDto extends GuestFormDto {
  readonly addedToAnyOtherCharter: number;
}

export class AddCharterGuestPayload {
  [key: string]: unknown;

  readonly boatId: number;
  readonly charterId: number | null;
  readonly firstName: string;
  readonly lastName: string;
  readonly email: string | null;
  readonly image: string | null;
  readonly genderId: number;
  readonly typeId: number;
  readonly dateBirth: string | null;
  readonly passportNumber: string | null;
  readonly passportIssueCountryId: number | null;
  readonly passportExpiryDate: string | null;
  readonly passportAttachments: string[] | null = null;
  readonly passportOriginalAttachments: string[] | null = null;
  readonly documentAttachments: string[] | null = null;
  readonly documentOriginalAttachments: string[] | null = null;
  readonly notes: string | null;
  readonly fleets: number[] | null;
  readonly removeFleets: number[] | null;

  constructor(
    boatId: number,
    charterId: number | null,
    form: GuestForm,
  ) {
    this.boatId = boatId;
    this.firstName = form.firstName;
    this.lastName = form.lastName;
    this.email = form.email;
    this.genderId = form.gender;
    this.typeId = form.type;
    this.dateBirth = form.dateBirth;
    this.passportNumber = form.passportNumber;
    this.passportIssueCountryId = form.passportIssueCountry;
    this.passportExpiryDate = form.passportExpiryDate;
    const {passportAttachments, documentAttachments, image} = form;
    if (image?.file) {
      this.image = parseUploadFileName(image) || null;
    }
    if (passportAttachments) {
      this.passportAttachments = parseUploadFileNames(passportAttachments);
      this.passportOriginalAttachments = parseUploadOriginalNames(passportAttachments);
    }
    if (documentAttachments) {
      this.documentAttachments = parseUploadFileNames(documentAttachments);
      this.documentOriginalAttachments = parseUploadOriginalNames(documentAttachments);
    }
    this.notes = form.notes;
    this.charterId = charterId;
    form.phones.forEach((item, index) => this.setPhoneParams(item, index));
    this.fleets = form.addFleets || [];
    this.removeFleets = form.removeFleets;
  }

  private setPhoneParams(item: GuestPhoneForm, index: number) {
    const parentKey = `phones[${index}]`;
    this[`${parentKey}[phoneCountryCode]`] = item.phone?.phoneCountryCode;
    this[`${parentKey}[phoneNumber]`] = item.phone?.phoneNumber;
    this[`${parentKey}[viber]`] = booleanToConditionalStatus(item.viber);
    this[`${parentKey}[whatsapp]`] = booleanToConditionalStatus(item.whatsapp);
  }
}

export class UpdateCharterGuestPayload extends AddCharterGuestPayload {
  readonly guestId: number;
  readonly removePassportAttachments: number[] | null;
  readonly removeDocumentAttachments: number[] | null;
  readonly removeImage: ConditionalStatus.YES | null;

  constructor(
    boatId: number,
    guestId: number,
    charterId: number | null,
    form: GuestForm,
  ) {
    super(boatId, charterId, form);
    this.guestId = guestId;
    this.removePassportAttachments = form.removePassportAttachments;
    this.removeDocumentAttachments = form.removeDocumentAttachments;
    this.removeImage = form.removeImage;
  }
}

export interface CharterAllGuestListDto {
  guestList: CharterAllGuestItemDto[];
  pagination: PaginationDto;
}

export interface CharterAllGuestItemDto extends GuestInfoItemDto {
  readonly addedToCharter: boolean;
  readonly addedToAnyOtherCharter: boolean;
  readonly charterGuest: number | null;
}

export class AddGuestsToCharterPayload {
  constructor(
    public readonly charterId: number,
    public readonly guestIds: number[],
  ) {
  }
}

export interface AddGuestsToCharterResponse {
  charterGuestIds: number[];
}

export class MaxGuestForm {
  constructor(
    public readonly maxGuest: number,
  ) {
  }
}

export class UpdatesMaxGuestsPayload {
  constructor(
    public readonly charterId: number,
    public readonly maxGuest: number,
  ) {
  }
}

export class RemoveGuestFromCharterPayload {
  constructor(
    public readonly charterId: number,
    public readonly guestId: number,
  ) {
  }
}
