import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  forwardRef,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR, UntypedFormControl} from '@angular/forms';
import {BehaviorSubject, combineLatest, Observable} from 'rxjs';
import {map, startWith, takeUntil} from 'rxjs/operators';

import {PhoneNumber} from '@controls/phone-number/models/phone-number.model';
import {SelectComponent} from '@controls/select/components/select/select.component';
import {CountryDto} from '@models/directories';

import {SeazoneFormFieldWrapper} from '../../../helpers/seazone-form-field-wrapper';

@Component({
  selector: 'app-phone-number',
  templateUrl: './phone-number.component.html',
  styleUrls: ['./phone-number.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => PhoneNumberComponent),
      multi: true,
    },
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PhoneNumberComponent extends SeazoneFormFieldWrapper implements OnInit, OnChanges, ControlValueAccessor, OnDestroy {

  selectedCountry: CountryDto | null;
  filter = new UntypedFormControl('');
  phoneControl = new UntypedFormControl();
  codeControl = new UntypedFormControl();
  disabled = false;
  isActive = false;
  @Input() countries: CountryDto[];
  @Input() errors;

  @ViewChild('container', {static: true}) controlContainer: ElementRef<HTMLElement>;
  @ViewChild('phoneInput', {static: true}) phoneInput: ElementRef<HTMLInputElement>;
  @ViewChild('searchCountryFlag', {static: true}) searchCountryFlag: ElementRef<HTMLInputElement>;
  @ViewChild(SelectComponent, {static: true}) phoneSelectComponent: SelectComponent;

  private countriesList$ = new BehaviorSubject<CountryDto[]>([]);
  private writeValue$ = new BehaviorSubject<PhoneNumber | null>(null);

  countries$: Observable<CountryDto[]> = combineLatest([
    this.filter.valueChanges.pipe(startWith('')),
    this.countriesList$,
  ]).pipe(
    map(([value, countries]: [string, CountryDto[]]) => {
      if (!countries.length) {
        return [];
      }
      if (!value) {
        return countries;
      }
      return countries.filter(country => country.name.toLowerCase().startsWith(value.toLowerCase()));
    }),
  );

  onChangeFn = (_: any) => {
  };

  onTouchedFn = () => {
  };

  constructor(
    private readonly cdr: ChangeDetectorRef,
  ) {
    super();
  }

  ngOnInit() {
    const control = this.phoneControl;
    control.disable();
    if (!control) {
      return;
    }
    control.valueChanges.pipe(
      takeUntil(this.destroyStream$),
    ).subscribe(value => {
      if (control.invalid) {
        return;
      }
      const country = this.selectedCountry;
      if (!country) {
        this.onChangeFn(null);
        return;
      }
      const phoneNumber = new PhoneNumber(country.alpha3Code, value);
      this.onChangeFn(phoneNumber);
    });

    combineLatest([this.countriesList$, this.writeValue$]).pipe(
      map(([countries, value]) => {
        if (!value) {
          return [null, null];
        }
        const selectedCountry = countries.find(country => country.alpha3Code === value.phoneCountryCode) || null;
        return [selectedCountry, value];
      }),
      takeUntil(this.destroyStream$),
    ).subscribe(([country, value]: [CountryDto | null, PhoneNumber | null]) => {
      const emitEvent = false;
      this.selectedCountry = country;
      this.codeControl.setValue(country, {emitEvent});
      this.phoneControl.setValue(value?.phoneNumber || null, {emitEvent});
      this.phoneControl.enable();
    });

    this.focusMonitor.monitor(this.controlContainer, true).pipe(
      takeUntil(this.destroyStream$),
    ).subscribe(origin => {
      this.zone.run(() => {
        this.setActiveState(!!origin);
      });
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    const countryChanges = changes.countries;
    const value = countryChanges && countryChanges.currentValue;
    if (value && value.length) {
      this.countriesList$.next(value);
    }
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
    this.countriesList$.complete();
    this.writeValue$.complete();
  }

  writeValue(value: PhoneNumber | null): void {
    this.writeValue$.next(value);
  }

  registerOnChange(fn: any): void {
    this.onChangeFn = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouchedFn = fn;
  }

  onTouched() {
    this.onTouchedFn();
  }

  onChange() {
  }

  onChangeSelection(selectedCountry: CountryDto | null): void {
    this.selectedCountry = selectedCountry;
    if (!selectedCountry) {
      this.phoneControl.disable();
      this.phoneControl.setValue(null);
      this.cdr.detectChanges();
      return;
    }
    if (!this.writeValue$.value) {
      this.phoneControl.setValue(null);
    }
    if (selectedCountry && selectedCountry.id && !this.disabled) {
      this.phoneControl.enable();
      this.phoneInput.nativeElement.focus();
    }
    this.cdr.detectChanges();
  }

  onClickCountryFlag(): void {
    this.phoneSelectComponent.showDropdown();
  }

  dropdownShown(): void {
    this.searchCountryFlag.nativeElement.focus();
    this.searchCountryFlag.nativeElement.click();
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
    if (isDisabled) {
      this.codeControl.disable();
      this.phoneControl.disable();
      return;
    }
    this.codeControl.enable();
    this.phoneControl.enable();
  }

  private setActiveState(isActive: boolean): void {
    this.isActive = isActive;
    this.cdr.markForCheck();
  }

}
