import {EventEmitter} from '@angular/core';
import {BehaviorSubject} from 'rxjs';

export class CharterHeaderAction {
  constructor(
    public readonly name: string,
    public readonly styles: string[],
    public readonly event: EventEmitter<void>,
    public readonly disabled: BehaviorSubject<boolean>,
  ) {
  }
}
