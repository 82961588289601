import {Moment} from 'moment';
import moment from 'moment-timezone';

import {INPUT_TIME_PATTERN} from '@helpers/patterns';
import {MomentFormats} from '@services/moment/moment.service';

export class DateTimeUtcConverter {

  static convertDateTimeToUtc(date: string, time: string, timeZoneName: string | null = null)
    : Moment | null {
    if (!date || !time) {
      return null;
    }
    if (!INPUT_TIME_PATTERN.test(time)) {
      return null;
    }
    if (timeZoneName) {
      return moment.tz(`${date} ${time}:00`, timeZoneName).utc();
    }
    return moment(`${date} ${time}:00`).utc();
  }

  static convertDateToUtc(date: string, timeZoneName: string | null = null)
    : Moment | null {
    if (!date) {
      return null;
    }
    if (timeZoneName) {
      return moment.tz(date, timeZoneName).utc();
    }
    return moment(date).utc();
  }

  static convertDateTimeToUtcString(date: string, time: string, timeZoneOffset: string | null = null)
    : string | null {
    const dateTime = DateTimeUtcConverter.convertDateTimeToUtc(date, time, timeZoneOffset);
    return dateTime ? dateTime.format(MomentFormats.DATE_TIME) : null;
  }

  static convertDateTimeToLocal(dateTime: string | null, timeZoneName: string | null = null, isUtc = true): Moment | null {
    if (!dateTime) {
      return null;
    }
    if (timeZoneName) {
      return moment.utc(`${dateTime}`).tz(timeZoneName);
    }
    return moment.utc(dateTime);
  }

}
