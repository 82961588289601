import {Params} from '@angular/router';
import {UserPermissions} from '@modules/permissions';

import {parseParamsToArray} from '@helpers/parse-params-to-array';

import {
  CharterCompletedStatus,
  CharterPeriodStatus,
  CharterShortInfo,
  CharterShortInfoI,
  CharterProfit,
} from './charter-short-info.model';
import {CharterCommonType, SystemCharterType} from './charter.model';
import {CharterPeriodStatusItem, CharterTypeItem} from './common';

import {CharterContractStatusDto, CountryDto, CurrencyDto} from '../directories';
import {ReturnUrl,ConditionalStatus} from '../general';
import {PaginationDto} from '../response';
import {SortByItem} from '../shared';

export interface CharterListApaDataI {
  value: number;
  lastDateUploaded: string;
  currency: CurrencyDto;
}

export interface CharterListApaItemI {
  apaEnabled: boolean;
  data: CharterListApaDataI | null;
}

export interface CharterListItemI extends CharterShortInfoI {
  apaBalance: CharterListApaItemI;
  profit: CharterProfit;
}

export class CharterListData {
  constructor(
    public readonly statistic: CharterListStatisticsDto,
    public readonly charterRunning: CharterListItem[] | null,
    public readonly list: CharterListItem[] | null,
    public readonly pagination: PaginationDto,
  ) {
  }
}

export interface CharterListStatistic {
  charters: number;
  grossIncome: number;
  netIncome: number;
}

export interface CharterListDaysStatistic extends CharterListStatistic {
  days: number;
}

export interface CharterListHoursStatistic extends CharterListStatistic {
  hours: number;
}

export interface CharterListStatisticsDto {
  term: CharterListDaysStatistic;
  daily: CharterListHoursStatistic;
}

export class CharterListStatistics {
  term: CharterListStatisticsItem[];
  daily: CharterListStatisticsItem[];
}

export class CharterListStatisticsItem {
  constructor(
    public readonly name: keyof CharterListDaysStatistic | keyof CharterListHoursStatistic,
    public readonly title: string,
    public readonly isMoney: boolean,
    public readonly permissions: UserPermissions[] | null = null,
    public readonly hint?: string,
    public readonly numberClass?: string,
    public readonly textClass?: string,
  ) {
  }
}

export class CharterListItem extends CharterShortInfo {
  readonly apaBalance: CharterListApaItem | null;
  readonly profit: CharterProfit | null;

  constructor(data: CharterListItemI) {
    super(data);
    this.apaBalance = new CharterListApaItem(data.apaBalance);
    this.profit = new CharterProfit(data.profit);
  }

}

export class CharterListItemApaData {
  readonly value: number;
  readonly lastDateUploaded: string;
  readonly currency: CurrencyDto;

  constructor(data: CharterListApaDataI) {
    this.value = data.value;
    this.lastDateUploaded = data.lastDateUploaded;
    this.currency = data.currency;
  }
}

export class CharterListApaItem {
  readonly apaEnabled: boolean;
  readonly data: CharterListApaDataI | null;

  constructor(item: CharterListApaItemI | null) {
    this.apaEnabled = item ? item.apaEnabled : false;
    this.data = item && item.data || null;
  }
}

export interface CharterSearchSortForm {
  search: string | null;
}

export interface CharterSearchSortParams {
  search: string | null;
}

export interface CharterLocationFilterForm {
  date: string | null;
  country: string | null;
  city: string | null;
}

export class CharterLocationFilterDirectories {
  constructor(
    public readonly countries: CountryDto[],
  ) {
  }
}

export interface CharterFilterForm {
  periodStatus: CharterPeriodStatus[] | null;
  charterType: CharterCommonType[] | null;
  contractType: number[] | null;
  completed: boolean | null;
  notCompleted: boolean | null;
  locationStart: CharterLocationFilterForm | null;
  locationEnd: CharterLocationFilterForm | null;
}

export interface CharterFilterParamsList {
  periodStatus: CharterPeriodStatus[] | null;
  charterType: CharterCommonType[] | null;
  contractType: number[] | null;
  completed: ConditionalStatus.YES | null;
  notCompleted: ConditionalStatus.YES | null;
  dateTimeStart: string | null;
  countryStart: string | null;
  cityStart: string | null;
  dateTimeEnd: string | null;
  countryEnd: string | null;
  cityEnd: string | null;
}

export type CharterFilterParams = {
  [key in keyof CharterFilterParamsList]?: any | any[];
};

export class CharterSearchSortDirectories {
  constructor(
    public readonly sortList: SortByItem[],
  ) {
  }
}

export class CharterFilterDirectories {
  constructor(
    public readonly charterContractStatus: CharterContractStatusDto[],
    public readonly charterPeriodStatus: CharterPeriodStatusItem[],
    public readonly charterType: CharterTypeItem[],
  ) {
  }
}

export class CharterListParamsPayload implements CharterFilterParamsList, CharterSearchSortParams {
  readonly boatId: number;
  readonly page: number;
  readonly limit: number;
  readonly search: string;
  readonly periodStatus: CharterPeriodStatus[] | null;
  readonly charterType: CharterCommonType[] | null;
  readonly contractType: number[] | null;
  readonly completed: ConditionalStatus.YES | null;
  readonly notCompleted: ConditionalStatus.YES | null;
  readonly dateTimeStart: string | null;
  readonly countryStart: string | null;
  readonly cityStart: string | null;
  readonly dateTimeEnd: string | null;
  readonly countryEnd: string | null;
  readonly cityEnd: string | null;

  constructor(params: Params) {
    this.boatId = params.boatId;
    this.page = params.page;
    this.limit = params.limit;
    this.search = params.search || '';
    this.periodStatus = parseParamsToArray(params.periodStatus, true);
    this.charterType = parseParamsToArray(params.charterType, true);
    this.contractType = parseParamsToArray(params.contractType, true);
    this.completed = params.completed || null;
    this.notCompleted = params.notCompleted || null;
    this.dateTimeStart = params.dateTimeStart || null;
    this.countryStart = params.countryStart || null;
    this.cityStart = params.cityStart || null;
    this.dateTimeEnd = params.dateTimeEnd || null;
    this.countryEnd = params.countryEnd || null;
    this.cityEnd = params.cityEnd || null;
  }
}

export interface RedirectCharterListItemPayload {
  id: number;
  status: CharterCompletedStatus;
}

export type RedirectCharterListItemReturnUrl = ReturnUrl.CHARTER_CALENDAR | ReturnUrl.BOAT_DASHBOARD | ReturnUrl.BOAT_CALENDAR | null;

export class ImportCharterListPayload {
  constructor(
    public readonly boatId: number,
    public readonly importFile: File,
  ) {
  }
}

export type CharterListReportFilter = CharterFilterParamsList & CharterSearchSortForm;

export interface RedirectEditCharterPayload {
  readonly id: number;
  readonly type: SystemCharterType;
}
