import {CharterListItem} from './charter-list.model';
import {DurationForm} from './charter-overview.model';

import {CharterListNotifyItem} from '../boat-charters';
import {OverviewButtons} from '../general';
import {PaginationDto} from '../response';

export interface CharterCalendarPayload {
  readonly boatId: number;
  readonly year: number;
  readonly month: number;
}

export class CharterCalendarListDto {
  constructor(
    public readonly monthly: CharterListItem[],
    public readonly upcoming: CharterListItem[],
    public readonly notify: CharterListNotifyItem[],
  ) {
  }
}

export interface CharterAvailableDaysForm extends DurationForm{
  period: number;
  quantity: number;
}

export class CharterAvailableDaysPayload {
  mode: string;
  year: number;
  period: number;
  quantity: number;
  page: number | null;
  limit: number | null;
  month?: number | null;
  yearTail?: number | null;
  monthTail?: number | null;
  constructor(
    form: CharterAvailableDaysFormPayload,
    options: CharterAvailableDaysDataFilter,
  ) {
    this.mode = form.mode;
    this.year = form.year;
    this.period = form.period;
    this.quantity = form.quantity;
    this.page = options.page;
    this.limit = options.limit;
    this.month = form.month;
    this.yearTail = form.yearTail;
    this.monthTail = form.monthTail;
  }
}
export interface CharterAvailableDaysFormPayload {
  mode: string;
  year: number;
  period: number;
  quantity: number;
  month?: number | null;
  yearTail?: number | null;
  monthTail?: number | null;
}

export interface CharterAvailableDaysParams {
  mode: OverviewButtons | null;
  year?: string | null;
  month?: string | null;
  yearTail?: string | null;
  monthTail?: string | null;
  period?: string | null;
  quantity?: string | null;
}

export class CharterAvailableDaysDataFilter {
  constructor(
    public page: number | null = null,
    public limit: number | null = null,
  ) {
  }
}

export class CharterAvailableDaysDto {
  constructor(
    public readonly models: CharterAvailableDaysItem[],
    public readonly pagination: PaginationDto,
  ) {
  }
}

export class CharterAvailableDaysItem {
  constructor(
    public readonly start: string,
    public readonly end: string,
    public readonly quantity: number,
  ) {
  }
}
