import {CharterPeriodStatus} from '../charter-short-info.model';
import {CharterCommonType, CharterContractStatus, CharterKindType, SystemCharterType} from '../charter.model';

export class CharterTypeItem {
  constructor(
    public readonly type: SystemCharterType,
    public readonly commonType: CharterCommonType,
    public readonly kind: CharterKindType | null,
    public readonly title: string,
    public readonly style: string | null = null,
  ) {
  }
}

export class PeriodTypeItem {
  constructor(
    public readonly key: PeriodType,
    public readonly name: string,
    public readonly color: string,
  ) {
  }
}

export enum PeriodType {
  Term = 1,
  Daily,
}

export class CharterPeriodStatusItem {
  constructor(
    public readonly id: CharterPeriodStatus,
    public readonly title: string,
    public readonly style: string | null = null,
  ) {
  }
}

export class CharterContractStatusItem {
  constructor(
    public readonly id: CharterContractStatus,
    public readonly title: string,
    public readonly style: string | null = null,
  ) {
  }
}
