<div class="form__row">
  <div class="form__block" [ngClass]="wrapperStyling">
    <p *ngIf="label" class="form__block-text" [class.required]="required">{{ label }}</p>
    <div class="phone-control-wrapper">
      <div
        class="form__input phone-control-container"
        [class.active]="isActive"
        [class.disabled]="disabled"
        [class.readonly]="readonly"
        #container
        appControlErrors>
        <div class="country-flag" (click)="onClickCountryFlag()">
          <img *ngIf="selectedCountry" [src]="selectedCountry?.flag" [alt]="selectedCountry?.name" />
        </div>
        <div class="country-select-container">
          <app-select
            (dropdownShown)="dropdownShown()"
            [parentContainer]="container"
            [arrowIndent]="true"
            [formControl]="codeControl"
            (selectionChange)="onChangeSelection($event)"
            [disableSelect]="readonly || disabled"
            hiddenInput="true">
            <app-select-option class="country-search" notSelectable="true">
              <div class="search-option">
                <label class="search-input">
                  <span class="search-input-icon"><i class="icon-search"></i></span>
                  <input #searchCountryFlag type="text" [formControl]="filter" placeholder="Country" />
                </label>
              </div>
            </app-select-option>
            <app-select-option *ngIf="codeControl.value" class="phone-select-option" [value]="null">
              <div class="select-container">
                {{ 'formFields.resetCountryCode' | transloco }}
              </div>
            </app-select-option>
            <app-select-option class="phone-select-option" *ngFor="let country of countries$ | async" [value]="country">
              <div class="select-container">
                <div class="country-flag">
                  <img [src]="country?.flag" [alt]="country?.name" />
                </div>
                {{ country?.name }}, +{{ country?.phoneCode }}
              </div>
            </app-select-option>
          </app-select>
        </div>
        <div class="phone-code-container"> +{{ selectedCountry?.phoneCode }} </div>
        <div class="phone-input">
          <input
            #phoneInput
            type="text"
            inputmode="numeric"
            class="full-width"
            [formControl]="phoneControl"
            [readOnly]="readonly"
            [placeholder]="placeholder || ''" />
        </div>
      </div>
    </div>
  </div>
</div>
